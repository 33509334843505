import React from "react"
import "../../../styles/components/form.scss"

const UserDetails = ({
  prevStep,
  nextStep,
  errors,
  handleChange,
  values,
  props,
  handleSubmit,
}) => {
  const Continue = e => {
    e.preventDefault()
    nextStep()
  }

  const Previous = e => {
    e.preventDefault()
    prevStep()
  }

  return (
    <>
      <h4> {props.rtFHd3} </h4>

      {/* first name */}
      <div className="block pt-3">
        <label for="firstName">{props.rtFTitle2}</label>
        <input
          type="text"
          name="firstName"
          id="firstName"
          onChange={handleChange}
        />
      </div>
      {errors.firstName.length > 0 && (
        <span className="error">{errors.firstName}</span>
      )}

      {/* last name */}
      <div className="block pt-3">
        <label for="lastName">{props.rtFTitle3}</label>
        <input type="text" name="lastName" onChange={handleChange} />
      </div>
      {errors.lastName.length > 0 && (
        <span className="error">{errors.lastName}</span>
      )}

      {/* phoneNumber */}
      <div className="block pt-3">
        <label for="phoneNumber">{props.rtFTitle4}</label>
        <input
          type="text"
          id="phoneNumber"
          inputMode="numeric"
          name="phoneNumber"
          onChange={handleChange}
        />
      </div>
      {errors.phoneNumber.length > 0 && (
        <span className="error">{errors.phoneNumber}</span>
      )}

      <div className="row justify-content-center pt-3">
        <button
          className="btn"
          onClick={Continue}
          fullWidth
          variant="contained"
          color="primary"
        >
          {props.rtFBtn}
        </button>
      </div>
    </>
  )
}

export default UserDetails
