import React from "react"
import LayoutNoHeader from "../components/layoutWithoutHeader"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import RegisterForm from "../components/Organisms/RegisterForm/RegisterForm"
import config from "../utils/siteConfig"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import RegisterFormStep from "../components/Organisms/RegistrationFormStep/RegistrationFormStep"

export default function Register({ data }) {
    debugger;
    const page = data.allContentfulZRegister.edges[0].node
    const options = {
        renderNode: {
            [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="heading1">{children}</h1>
            ),
            [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="heading2">{children}</h2>
            ),
            [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="heading3">{children}</h3>
            ),
            [BLOCKS.HEADING_4]: (node, children) => (
                <h4 className="heading4">{children}</h4>
            ),
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
                <img src={`https:${node.data.target.fields.file["en-US"].url}`} />
            ),
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="copy">{children}</p>
            ),

        },
        renderMark: {},
        renderText: (text) =>
            text
                .replace(/\u2028/g, "")
                .split("\n")
                .flatMap((text, i) => [i > 0 && <br />, text])
    }

    const {
        id,
        metaData,
        rtFBtn,
        rtFConsent,
        rtFHd,
        rtFSignin,
        rtFTitle,
        rtFTitle3,
        rtFTitle2,
        rtFTitle4,
        rtRtxt,
        rtRtxt2,
        rtRtxt3,
        rtRtxt4,
    } = page

    return (
        <LayoutNoHeader>
            <head>
                <title>{metaData.title}</title>
                <meta charset="utf-8" />
                <meta name="robots" content="noindex" />
            </head>

            <section>
                <div className="register-container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 pt-5 pr-5">
                            <div className="row pt-5 pl-4">
                                <a href={config.navigationURL_home}>
                                    <img
                                        src={require("../images/Logo/wunderloop_logo.svg")}
                                        style={{ width: "80%" }}
                                        alt="Wunderloop logo"
                                    />
                                </a>
                            </div>
                            <div className="row flex-nowrap pl-3 pt-5">
                                <FontAwesomeIcon className="faRegisterCheck mt-1 mr-3" icon={faCheckCircle} />
                                <h4 className="h4-register">
                                    {documentToReactComponents(rtRtxt.json, options)}
                                </h4>
                            </div>
                            <div className="row flex-nowrap pl-3 pt-4">
                                <FontAwesomeIcon className="faRegisterCheck mt-1 mr-3" icon={faCheckCircle} />
                                <h4 className="h4-register">
                                    {documentToReactComponents(rtRtxt2.json, options)}
                                </h4>
                            </div>
                            <div className="row flex-nowrap pl-3 pt-4">
                                <FontAwesomeIcon className="faRegisterCheck mt-1 mr-3" icon={faCheckCircle} />
                                <h4 className="h4-register">
                                    {documentToReactComponents(rtRtxt3.json, options)}
                                </h4>
                            </div>
                            <div className="row flex-nowrap pl-3 pt-3">
                                <h4 className="h4-register2 footercompany">
                                    {documentToReactComponents(rtRtxt4.json, options)}
                                </h4>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <RegisterFormStep  {...page} />
                        </div>
                    </div>
                </div>
            </section>
        </LayoutNoHeader>
    );
}


export const query = graphql`
query($locale: String!) {
    allContentfulZRegister(
        filter: {
        node_locale: { eq: $locale }
    }) {
        edges {
            node {
                id
                metaData {
                    slug
                    title
                    description
                    keywords {
                        keywords
                    }
                }
                rtFSignin {
                    json
                }
                rtFBtn
        rtFBtn2
        rtFBtn3
        rtFConsent
        rtFConsent2
        rtFHd
        rtFHd2
        rtFHd3
        rtFHd4
        rtFTitle
        rtFTitle2
        rtFTitle3
        rtFTitle4
        rtFTitle5
        rtFTitle6
        rtFTitle7
        rtFTitle8
        rtFPh
                rtRtxt {
                    json
                }
                rtRtxt2 {
                    json
                }
                rtRtxt3 {
                    json
                }
                rtRtxt4 {
                    json
                }
            }
        }
    }
}
`