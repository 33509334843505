import React from "react"
import "../../../styles/components/form.scss"

const CompanyDetails = ({
  prevStep,
  nextStep,
  errors,
  handleChange,
  values,
  props,
  handleSubmit,
}) => {
  const Previous = e => {
    e.preventDefault()
    prevStep()
  }

  return (
    <>
      <h4> {props.rtFHd4} </h4>

      {/* company name */}
      <div className="block pt-3">
        <label for="companyName">{props.rtFTitle5}</label>
        <input
          type="text"
          id="companyName"
          name="companyName"
          onChange={handleChange}
        />
      </div>
      {errors.companyName.length > 0 && (
        <span className="error">{errors.companyName}</span>
      )}

      {/* companySize */}
      <div className="block pt-3">
        <label for="employeesNumber">{props.rtFTitle6}</label>
        <select
          type="text"
          id="employeesNumber"
          name="employeesNumber"
          onChange={handleChange}
        >
          <option value="" disabled selected hidden>
            {props.rtFPh}
          </option>
          <option value="1-99">1-99</option>
          <option value="100-999">100-999</option>
          <option value="1000-4999">1000-4999</option>
          <option value="5000+">5000+</option>
        </select>
      </div>
      {errors.employeesNumber.length > 0 && (
        <span className="error">{errors.employeesNumber}</span>
      )}

      {/* wunderloop Subdomain */}
      <div className="block pt-3">
        <label for="wunderloopSubdomain">{props.rtFTitle7}</label>
        <input
          type="text"
          id="wunderloopSubdomain"
          name="wunderloopSubdomain"
          onChange={handleChange}
        />
      </div>
      {errors.wunderloopSubdomain.length > 0 && (
        <span className="error">{errors.wunderloopSubdomain}</span>
      )}

      {/* password */}
      <div className="block pt-3">
        <label for="password">{props.rtFTitle8}</label>
        <input
          type="password"
          name="password"
          id="password"
          inputMode="password"
          onChange={handleChange}
        />
      </div>
      {errors.password.length > 0 && (
        <span className="error">{errors.password}</span>
      )}

      <div className="row justify-content-center pt-3">
        <button
          className="btn"
          type="submit"
          name="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          {props.rtFBtn3}
        </button>
      </div>
    </>
  )
}

export default CompanyDetails
