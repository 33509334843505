import React from "react"
import "../../../styles/components/form.scss"

const AccountDetails = ({
  nextStep,
  handleChange,
  errors,
  values,
  props,
  handleSubmit,
}) => {
  // for continue event listener
  const Continue = e => {
    e.preventDefault()
    nextStep()
  }

  return (
    <>
      <h4> {props.rtFHd} </h4>
      <h5> {props.rtFHd2} </h5>
      <div className="block pt-3">
        <label for="email">{props.rtFTitle}</label>
        <input
          type="email"
          name="email"
          id="email"
          inputMode="email"
          onChange={handleChange}
          title="Enter correct e-mail format"
        />
      </div>
      {errors.email.length > 0 && <span className="error">{errors.email}</span>}

      <div className="row justify-content-start pl-3 pt-3">
        <p className="checkboxHolder">
          <input type="checkbox" name="subscription" onChange={handleChange} />
          {props.rtFConsent}
        </p>
      </div>

      <br />
      <div className="row justify-content-center pt-3">
        <button
          className="btn"
          onClick={Continue}
          fullWidth
          variant="contained"
          color="primary"
        >
          {props.rtFBtn}
        </button>
      </div>

      <p hidden>
        <div className="block pt-3">
          <label for="firstName">{props.rtFTitle2}</label>
          <input
            type="text"
            name="firstName"
            id="firstName"
            onChange={handleChange}
          />
        </div>
        {errors.firstName.length > 0 && (
          <span className="error">{errors.firstName}</span>
        )}

        {/* last name */}
        <div className="block pt-3">
          <label for="lastName">{props.rtFTitle3}</label>
          <input type="text" name="lastName" onChange={handleChange} />
        </div>
        {errors.lastName.length > 0 && (
          <span className="error">{errors.lastName}</span>
        )}

        {/* phoneNumber */}
        <div className="block pt-3">
          <label for="phoneNumber">{props.rtFTitle4}</label>
          <input
            type="text"
            id="phoneNumber"
            inputMode="numeric"
            name="phoneNumber"
            onChange={handleChange}
          />
        </div>
        {errors.phoneNumber.length > 0 && (
          <span className="error">{errors.phoneNumber}</span>
        )}
        {/* company name */}
        <div className="block pt-3">
          <label for="companyName">{props.rtFTitle5}</label>
          <input
            type="text"
            id="companyName"
            name="companyName"
            onChange={handleChange}
          />
        </div>
        {errors.companyName.length > 0 && (
          <span className="error">{errors.companyName}</span>
        )}

        {/* companySize */}
        <div className="block pt-3">
          <label for="employeesNumber">{props.rtFTitle6}</label>
          <select
            type="text"
            id="employeesNumber"
            name="employeesNumber"
            onChange={handleChange}
          >
            <option value="" disabled selected hidden>
              {props.rtFPh}
            </option>
            <option value="1-99">1-99</option>
            <option value="100-999">100-999</option>
            <option value="1000-4999">1000-4999</option>
            <option value="5000+">5000+</option>
          </select>
        </div>
        {errors.employeesNumber.length > 0 && (
          <span className="error">{errors.employeesNumber}</span>
        )}

        {/* wunderloop Subdomain */}
        <div className="block pt-3">
          <label for="wunderloopSubdomain">{props.rtFTitle7}</label>
          <input
            type="text"
            id="wunderloopSubdomain"
            name="wunderloopSubdomain"
            onChange={handleChange}
          />
        </div>
        {errors.wunderloopSubdomain.length > 0 && (
          <span className="error">{errors.wunderloopSubdomain}</span>
        )}

        {/* password */}
        <div className="block pt-3">
          <label for="password">{props.rtFTitle8}</label>
          <input
            type="password"
            name="password"
            id="password"
            inputMode="password"
            onChange={handleChange}
          />
        </div>
        {errors.password.length > 0 && (
          <span className="error">{errors.password}</span>
        )}
      </p>
    </>
  )
}

export default AccountDetails
