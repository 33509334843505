import React, { Component } from "react"
import AccountDetails from "../../Molecules/AccountDetails/AccountDetails"
import CompanyDetails from "../../Molecules/CompanyDetails/CompanyDetails"
import UserDetails from "../../Molecules/UserDetails/UserDetails"
import "../../../styles/components/form.scss"
import { navigateTo } from "gatsby-link"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const validEmailRegex = new RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
)

const validPasswordRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
)

const validateForm = errors => {
  let valid = true
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    val => val.length > 0 && (valid = false)
  )
  return valid
}

export default class RegisterFormStep extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  state = {
    step: 1,
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    companyName: "",
    employeesNumber: "",
    wunderloopSubdomain: "",
    password: "",
    subscription: "",
    errors: {
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      companyName: "",
      employeesNumber: "",
      wunderloopSubdomain: "",
      password: "",
      subscription: "",
    },
  }

  prevStep = () => {
    const { step } = this.state
    this.setState({ step: step - 1 })
  }

  // proceed to the next step
  nextStep = () => {
    if (validateForm(this.state.errors)) {
      console.info("Valid Form")
      const { step } = this.state
      this.setState({ step: step + 1 })
    } else {
      console.error("Invalid Form")
    }
  }

  // handle field change
  handleChange = e => {
    e.preventDefault()
    //this.setState({ [input]: e.target.value });
    const { name, value } = e.target
    let errors = this.state.errors
    switch (name) {
      case "firstName":
        errors.firstName =
          value.length < 1 ? "Name must be at least 1 characters long!" : ""
        break
      case "lastName":
        errors.lastName =
          value.length < 1 ? "Name must be at least 1 characters long!" : ""
        break
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Email is not valid!"
        break
      case "phoneNumber":
        errors.phoneNumber =
          value.length < 1 ? "Number must be at least 1 characters long!" : ""
        break
      case "companyName":
        errors.companyName =
          value.length < 1 ? "Name must be at least 1 characters long!" : ""
        break
      case "employeesNumber":
        errors.employeesNumber =
          value.length < 1 ? "Number must be at least 1 characters long!" : ""
        break
      case "wunderloopSubdomain":
        errors.wunderloopSubdomain =
          value.length < 1
            ? "Subdomain must be at least 1 characters long!"
            : ""
        break
      case "password":
        errors.password = validPasswordRegex.test(value)
          ? ""
          : "Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
        break
      default:
        break
    }

    this.setState({ errors, [name]: value }, () => {
      console.log(errors)
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    Promise.all([
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state,
        }),
      }),
      fetch("https://getform.io/f/f8237525-74ff-4231-adc6-7a47da409cd5", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state,
        }),
      }),
    ])
      .then(() => navigateTo("/confirmation"))
      .catch(error => alert(error))
  }

  render() {
    const { step } = this.state
    const {
      email,
      firstName,
      lastName,
      phoneNumber,
      companyName,
      employeesNumber,
      wunderloopSubdomain,
      password,
      subscription,
      errors,
    } = this.state

    const values = {
      email,
      firstName,
      lastName,
      phoneNumber,
      companyName,
      employeesNumber,
      wunderloopSubdomain,
      password,
      subscription,
    }

    return (
      <div className="registerForm">
        <div>
          <form
            name="register"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="register" />
            <p hidden>
              <label>
                Don’t fill this out:{" "}
                <input name="bot-field" onChange={this.handleChange} />
              </label>
            </p>
            {step === 1 ? (
              <AccountDetails
                nextStep={this.nextStep}
                handleChange={this.handleChange}
                values={values}
                errors={errors}
                props={this.props}
              />
            ) : step === 2 ? (
              <UserDetails
                prevStep={this.prevStep}
                nextStep={this.nextStep}
                handleChange={this.handleChange}
                values={values}
                errors={errors}
                props={this.props}
              />
            ) : (
              <CompanyDetails
                prevStep={this.prevStep}
                nextStep={this.nextStep}
                handleChange={this.handleChange}
                values={values}
                errors={errors}
                props={this.props}
                handleSubmit={this.handleSubmit}
              />
            )}
          </form>
        </div>
      </div>
    )
  }
}
